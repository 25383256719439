<template>
    <el-dialog
        title="手机登录"
        :visible.sync="phoneLoginVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closePhoneLogin('phoneForm')"
    >
        <el-form
            :model="phone"
            :rules="rulesPhone"
            ref="phoneForm"
            label-width="100px"
        >
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="phone.phone"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="验证码" prop="phone_code">
                        <el-input v-model="phone.phone_code"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <div class="div_btn">
                        <el-button
                            v-if="!sendPhoneTime"
                            type="primary"
                            plain
                            @click="sendPhone('phoneForm')"
                            >获取</el-button
                        >
                        <el-button v-else type="primary" plain disabled
                            >重发({{ sendPhoneTime }})</el-button
                        >
                    </div>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer">
            <el-button plain @click="closePhoneLogin('phoneForm')"
                >取消</el-button
            >
            <el-button type="primary" plain @click="submitPhoneLogin('phoneForm')"
                >登录</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { phone_login_code_request,phone_login_request } from '@/network/user.js'

export default {
    name: 'phoneLoginComponent',
    data() {
        return {
            phone: {
                phone: '',
                phone_code: '',
            },
            rulesPhone: {
                phone: [
                    {
                        required: true,
                        message: '请填写新手机号',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^1[3,5,6,7,8,9]\d{9}$/,
                        message: '手机号格式不正确',
                        trigger: 'blur',
                    },
                ],
            },
            sendPhoneTime: 0,
        }
    },
    computed: {},
    methods: {
        closePhoneLogin(formName) {
            this.$emit('exitPhoneLogin')
            this.$refs[formName].resetFields()
        },
        sendPhone(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.sendPhoneTime = 60
                phone_login_code_request(this.phone)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('验证码已发送!')
                            const changeSendPhoneTime = setInterval(() => {
                                if (this.sendPhoneTime) {
                                    this.sendPhoneTime--
                                } else {
                                    clearInterval(changeSendPhoneTime)
                                }
                            }, 1000)
                        } else {
                            this.$message.error(s.msg)
                            this.sendPhoneTime = 0
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.sendPhoneTime = 0
                    })
            })
        },
        submitPhoneLogin(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                phone_login_request(this.phone)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('登录成功')
                            this.$router.replace('/')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => this.closePhoneLogin(formName))

                this.$emit('exitPhoneLogin')
            })
        },
    },
    filters: {},
    props: {
        phoneLoginVisible: Boolean,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>