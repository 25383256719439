<template>
    <div class="login_container">
        <div class="login_box">
            <div class="avatar_box">
                <img
                    src="../../assets/img/login/login_avatar.jpg"
                    class="avatar_img"
                />
            </div>
            <el-form
                class="login_form"
                ref="user"
                :model="user"
                :rules="verification"
            >
                <h2>宇圣通办公管理系统</h2>
                <el-form-item prop="username">
                    <el-input
                        placeholder="请输入用户名"
                        v-model="user.username"
                        prefix-icon="el-icon-user"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        placeholder="请输入密码"
                        @keyup.enter.native="onLogin"
                        v-model="user.password"
                        prefix-icon="el-icon-lock"
                        show-password
                    ></el-input>
                </el-form-item>
                <el-form-item class="btn_iteam">
                    <el-button
                        class="btn_login"
                        type="primary"
                        plain
                        v-loading.fullscreen.lock="login_loading"
                        @click="validate_login('user')"
                        >登录</el-button
                    >
                    <el-button
                        class="btn_reset"
                        type="warning"
                        plain
                        @click="resetForm('user')"
                        >重置</el-button
                    >
                </el-form-item>
                <el-form-item class="btn_iteam">
                    <a><img src="../../assets/img/login/wx.jpg" class="wx_img" href="javascript:;" @click.prevent="wx_login()"></a>
                    <a><img src="../../assets/img/login/phone.jpg" class="phone_img" href="javascript:;" @click.prevent="phone_login()"></a>
                </el-form-item>
                <wx-login-component
                    :ftt="ftt"
                    :wx_url="fwx_url"
                    :wxLoginVisible="fwxLoginVisible"
                    @exitwxLogin="exitwxLogin"
                ></wx-login-component>
                <phone-login-component
                    :phoneLoginVisible="fphoneLoginVisible"
                    @exitPhoneLogin="exitPhoneLogin"
                ></phone-login-component>
            </el-form>
        </div>
        <div class="beian">
            <el-link type="primary" href="https://beian.miit.gov.cn/" target="_blank"><b>鄂ICP备19025863号-2</b></el-link>
            
        </div>
        <div class="beian2">
            <el-link type="primary" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010602004631" target="_blank"><img src="../../assets/img/login/waba.png" style="float:left;"/><b>鄂公网安备 42010602004631号</b></el-link>
        </div>
    </div>
</template>

<script>
import wxLoginComponent from '@/components/login/wxLoginComponent'
import phoneLoginComponent from '@/components/login/phoneLoginComponent'

import { staff_login_request } from '@/network/user.js'
import { we_chat_qr_code_request,we_chat_poll } from '@/network/wx.js'
import '@/assets/style/login/login_view.css'

export default {
    name: 'LoginView',
    data() {
        return {
            login_loading: false,
            user: {
                username: '',
                password: '',
            },
            verification: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '请输密码',
                        trigger: 'blur',
                    },
                    {
                        min: 8,
                        max: 16,
                        message: '密码长度必须在8-16位',
                        trigger: 'blur',
                    },
                ],
            },
            fwxLoginVisible: false,
            fphoneLoginVisible: false,
            fwx_url: '',
            ftt: 0,
            qr_code_time:0
        }
    },
    computed: {},
    methods: {
        validate_login(ref) {
            this.$refs[ref].validate((valid) => {
                if (!valid) {
                    return
                }
                this.onLogin()
            })
        },
        onLogin() {
            ;(this.login_loading = true),
                staff_login_request(this.user)
                    .then((s) => {
                        this.login_loading = false
                        if (s.status === 0) {
                            this.$message.success('登录成功')
                            this.$router.replace('/')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.login_loading = false
                        this.$message.error(err)
                    })
        },
        resetForm(ref) {
            this.$refs[ref].resetFields()
        },
        wx_login() {
            we_chat_qr_code_request({type:'login'})
                .then((s) => {
                    if (s.status === 0) {
                        this.fwx_url = s.result.url
                        this.qr_code_time = s.result.time
                        this.fwxLoginVisible = true
                        this.ftt = 120
                        const changeSendWxTime = setInterval(() => {
                            if (this.ftt) {
                                if(this.ftt % 2 === 0) //两秒轮询一次
                                {
                                    we_chat_poll({type:'login',time:this.qr_code_time})
                                        .then((s) => {
                                            if(s.status === 1) {
                                                clearInterval(changeSendWxTime)
                                                this.$message.success('登录成功')
                                                this.$router.replace('/')
                                            } else if(s.status === 2) {
                                                clearInterval(changeSendWxTime)
                                                this.$alert(
                                                    s.msg,
                                                    '温馨提示',
                                                    {
                                                        confirmButtonText: '确定',
                                                        callback: (action) => {
                                                            this.exitwxLogin()
                                                        },
                                                    }
                                                )
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err)
                                        })
                                }
                                this.ftt--
                            } else {
                                clearInterval(changeSendWxTime)
                            }
                        }, 1000)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        phone_login() {
            this.fphoneLoginVisible = true
        },
        exitwxLogin() {
            this.fwxLoginVisible= false
            this.ftt = 0
        },
        exitPhoneLogin() {
            this.fphoneLoginVisible= false
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        wxLoginComponent,
        phoneLoginComponent
    },
}
</script>