<template>
    <el-dialog
        title="微信登录"
        :visible.sync="wxLoginVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closewxLogin()"
    >
        <div class="div_new_image">
            <img v-if="tt" :src="wx_url" class="wx_qr_code" />
            <img v-else src="../../assets/img/login/login_avatar.gif" class="wx_qr_code" />
        </div>
        <div class="msg" style="color:#FF0000">
            温馨提示：
        </div>
        <p class="msg">
            如果您未进行账号与微信绑定，则无法进行扫码登录
        </p>
        <p class="msg" style="color:#FF0000">
            二维码有效期倒计时：
        </p>
        <h1 class="tt">
            <span v-if="tt>0">{{tt}}秒</span>
            <span v-else>二维码已过期</span>
        </h1>

        <span slot="footer" class="dialog-footer">
            <el-button plain @click="closewxLogin()">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name:'wxLoginComponent',
    data(){
        return {
            tt: ''
        }
    },
    computed:{},
    methods:{
        closewxLogin() {
            this.$emit('exitwxLogin')
        },
    },
    filters:{},
    props:{
        wxLoginVisible: Boolean,
        wx_url: String,
        ftt: Number
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        ftt(newVal) {
            this.tt = newVal
        }
    }
}
</script>

<style lang='less'>
.msg {
    text-align: center;
}
.tt {
    text-align: center;
    color: #8600FF;
}
.div_new_image {
    text-align: center;
}
.wx_qr_code {
    width: 150px;
    height: 150px;
}
</style>