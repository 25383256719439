import request from './request'

//获取二维码
export const we_chat_qr_code_request = p => {
    return request({
        method:'GET',
        url:'we_chat/qr_code',
        params: p
    })
}

//微信轮询
export const we_chat_poll = p => {
    return request({
        method:'GET',
        url:'we_chat/poll',
        params: p
    })
}
